<script>
export default {
	name: "Store",
	data(){
		return{
			nowStore: ["amart"]
		}
	},
	methods: {
		openDetail(target){
			// console.log("openDetail",target)
			let vm=this
			if(vm.nowStore.indexOf(target)===-1){
				vm.nowStore.push(target)
			}else{
				vm.nowStore.splice(vm.nowStore.indexOf(target),1)
			}
		}
	},
}
</script>
<template lang="pug" src="./template.pug"></template>
<style lang="sass" scoped src="./style.sass"></style>